<template>
  <v-select
    :items="areasLocales"
    v-model="areaSeleccionadaLocal"
    item-text="nombre"
    item-value="_id"
    :label="etiqueta"
    :no-data-text="noDataText()"
    :loading="loading"
    outlined
    hide-details
    dense
    @change="cambiarArea()"
  >
    <template v-if="menu" slot="prepend-inner">
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn fab icon v-on="on" x-small>
            <v-icon>mdi-dots-vertical </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-divider class="mx-3"></v-divider>
          <template v-for="(menu, index) in menuArea">
            <v-list-item
              :key="menu.text"
              dense
              :disabled="menu.disabled || false"
              @click="$emit(menu.action)"
            >
              <v-list-item-title>
                <font :color="menu.color || 'black'">{{ menu.text }}</font>
              </v-list-item-title>
              <v-list-item-icon v-if="menu.icon">
                <v-icon small :color="menu.color || 'grey darken-1'">{{
                  menu.icon
                }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-divider :key="index" class="mx-3"></v-divider>
          </template>
        </v-list>
      </v-menu>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    areas: { type: Array, default: () => {} },
    value: { type: String, default: () => "" },
    menu: { type: Boolean, default: false},
    etiqueta: {type: String, default: () => 'Área'},
    loading: { type: Boolean, default: false},
  },
  watch: {
    areas(value) {
      this.areasLocales = value;
    },
    value(value) {
      this.areaSeleccionadaLocal = value;
    },
  },
  data() {
    return {
      areasLocales: [],
      areaSeleccionadaLocal: this.value,
      menuArea: [
        { text: this.etiqueta == 'Área' ? "Agregar área" : "Agregar subárea", action: "agregarArea", icon: "mdi-plus" },
        { text: this.etiqueta == 'Área' ? "Modificar área" :"Modificar subárea", action: "modificarArea", icon: "mdi-pencil" },
      ],
    };
  },
  mounted() {
    this.areasLocales = this.areas;
  },
  methods: {
    cambiarArea() {
      this.$emit("input", this.areaSeleccionadaLocal);
      this.$emit("change", this.areaSeleccionadaLocal);
    },
    noDataText(){
      return this.etiqueta != 'Área' ? "No hay subáreas capturadas" : "No hay áreas capturadas"
    }
  },
};
</script>
