<template>
    <div>
        <v-card-text v-if="!expandir" class="ml-2">
            {{ texto.length > longitud ? texto.substring(0,this.longitud) : texto }}
            <a v-if="texto.length > longitud" class="link" @click="expandir=true">... ver más</a>
        </v-card-text>
            
        <v-card-text v-else class="d-flex text-start ml-2">
            {{texto}}
        </v-card-text>      
        <a v-if="expandir" class="d-flex justify-end link" @click="expandir=false"> ver menos</a>
    </div>
</template>

<script>
export default {
    name: 'textSubtract',
    props: {
        texto: { type: String, default: () => ''},
        longitud: { type: Number, default: () => 95 },
    },   
    data(){
        return{
            expandir:false
        }
    }
}
</script>

