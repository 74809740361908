
<template>
<div>
    <v-dialog v-model="mostrar" :max-width="500" persistent> 
        <v-card >
          <v-toolbar color="primary" dark dense flat class="text-h6">
           Archivo no disponible
            <v-spacer></v-spacer>
              <v-btn fab icon @click="$emit('cerrar')" x-small><v-icon color="white">mdi-close</v-icon> </v-btn>
          </v-toolbar>
            <v-container grid-list-md>
                <i>El documento </i><b>{{ nombreArchivo }}</b><i> ya no se encuentra disponible.</i>
            </v-container>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="$emit('cerrar')">Aceptar</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    name: 'noFile',
    props: {
        mostrar: { type: Boolean, default: false },
        nombreArchivo: { type: String, default: () => '' },
    },
    components: {},
    created(){
    },
    methods:{
    }
}
</script>

<style scoped>
</style>