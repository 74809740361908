<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="danger" dark dense flat class="text-h6"
        >Eliminar recurso</v-toolbar
      >

      <v-container grid-list-md>
        <b>¿Está seguro de elimiar el recurso?</b>
        <br />
        {{ recurso.nombre }} <br /><br>
        <i>Esta acción no podrá ser revertida.</i>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            small
            :disabled="loading"
            @click="cerrarForm()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="red"
            dark
            small
            :loading="loading"
            @click="eliminarRecurso()"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import { eliminarRecursoService } from "./recursos.service";

export default {
  props: {
    mostrar: { type: Boolean, default: false},  
    recurso: {type: Object, default: () => null}
  },
  data: () => ({
    loading: false,
  }),
  created() {},
  methods: {
    async eliminarRecurso() {
      try {

        this.loading = true;
        const serverResponse = await eliminarRecursoService(this.recurso.tipoRecurso, this.recurso._id );
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("recursoEliminado", this.recurso._id);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    cerrarForm() {
      this.$emit("cancelar");
    },
  },
};
</script>

<style scoped>
.formularioClass {
  padding: 15px;
  padding-bottom: 0;
}
</style>
