import {
    getServerRequest,
    postServerRequest,
    patchServerRequest,
    deleteServerRequest
  } from "@/components/globals/services/serverRequest.service";
  import { store } from "../../../../store/store";
  
  const httpURL = store.getters.httpURL;
  
  export const getAreasService= async (data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/recursos/areas/listado`;
    return await postServerRequest(url, {data},config);
  };
  
  export const crearAreaService = async (data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/recursos/area`;
    return await postServerRequest(url, data, config);
  }
  
  export const modificarAreaService = async (idArea, data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/recursos/area/${idArea}`;
    return await patchServerRequest(url, data, config);
  }
  
  export const getSubareasService= async (idArea) => {
    
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/recursos/${idArea}/subareas/listado`;
    return await getServerRequest(url, config);
  };
  
  export const crearSubareaService = async (idArea, data) => {
    
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/recursos/${idArea}/subarea`;
    return await postServerRequest(url, data, config);
  }
  
  export const modificarSubareaService = async (idArea, idSubArea, data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/recursos/${idArea}/${idSubArea}`;
    return await patchServerRequest(url, data, config);
  }
  
  export const getRecursosService= async (idArea, idSubarea, registros, pagina) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/recursos/listado/${idArea}/${idSubarea}/${registros}/${pagina}`;
    return await getServerRequest(url, config);
  };
  
  export const agregarRecursoArchivoService = async (data, uploadHandler, idArea, idSubarea) =>{
    const token = store.getters.sessionToken;
    const config = {
        headers: { token , 'Content-Type': 'multipart/form-data' },
        ...uploadHandler
    };
    const url = `${httpURL}/recursos/archivo/${idArea}/${idSubarea}`;
    return await postServerRequest(url, data, config);
  }

export const agregarRecursoEnlaceService = async (data, idArea, idSubarea) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/recursos/enlace/${idArea}/${idSubarea}`;
    return await postServerRequest(url, data, config);
}
  
  export const getRecusoService= async (data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/recursos/video/${data.area}/${data.subarea}/${data.idVideo}`;
    return await getServerRequest(url, config);
  };
    
  export const modificarRecursoService = async (data, idRecurso)=> {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/recurso/${idRecurso}`;
    return await patchServerRequest(url, data, config);
  }
    
  export async function eliminarRecursoService(tipo, idRecurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/recurso/${tipo}/${idRecurso}`;
    return await deleteServerRequest(url, config);
}

export const validaExistenciaService = async (data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/recursos/valida/existencia`;
  return await postServerRequest(url, data, config);
}