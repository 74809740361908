import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataFooter } from 'vuetify/lib/components/VDataIterator';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"outlined":""}},[_vm._t("table-title"),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[(!_vm.hidePagination)?_c('div',{staticClass:"d-flex"},[_c(VSelect,{staticClass:"pagina",attrs:{"items":_vm.paginas,"no-data-text":"No hay páginas disponibles","dense":""},on:{"change":function($event){return _vm.paginaChange()}},model:{value:(_vm.paginaSel),callback:function ($$v) {_vm.paginaSel=$$v},expression:"paginaSel"}}),_c(VDataFooter,{attrs:{"items-per-page-options":[10],"pagination":_vm.pagination,"options":_vm.pagination,"loading":_vm.loading,"page-text":"{0}-{1} de {2}"},on:{"update:options":function (e) { return _vm.$emit('update-page', e); }}})],1):_vm._e()]),_c(VCol,{attrs:{"cols":"6"}},[(!_vm.hideSearch)?_c(VTextField,{staticClass:"search",attrs:{"loading":_vm.loading,"append-icon":"mdi-magnify","label":"Búsqueda","hint":"Presione 'Enter' para buscar","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('update-search', _vm.search)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1)],1)],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.datos,"items-per-page":_vm.pagination.itemsPerPage || _vm.datos.length,"loading":_vm.loading,"hide-default-footer":true,"no-data-text":_vm.noDatosText},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
var headers = ref.headers;
return [_c('tbody',[_vm._l((items),function(item,row){return _c('tr',{key:item._id},_vm._l((headers),function(header,index){return _c('td',{key:("item-" + row + "-" + index)},[_vm._t(("item-" + (header.value)),function(){return [_vm._v(" "+_vm._s(item[header.value])+" ")]},{"value":item[header.value],"item":item,"row":row})],2)}),0)}),(items.length <= 0)?_c('tr',[_c('td',{attrs:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.noDatosText)+" ")])]):_vm._e()],2)]}}],null,true)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }