<template>
  <v-container>
    <v-card outlined>
      <v-card-text class="log">
        <span v-if="logs.length == 0">No se ha registrado actividad</span>
        <span v-for="(log, index) in logsLocal" :key="index">
          {{ log.fecha }} - {{ log.usuario.nombreCompleto }} - {{ log.accion }}
          <br />
        </span>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { DateTime } from "luxon";

export default {
  props: {
    logs: Array,
  },
  computed: {
    logsLocal() {
      const logs = [];
      this.logs.forEach((element) => {
        const fecha = DateTime.fromISO(element.fecha);
        logs.push({
          fecha: fecha.toLocaleString(DateTime.DATETIME_SHORT),
          usuario: element.usuario,
          accion: element.accion,
        });
      });
      return logs;
    },
  },
  data: () => ({}),
};
</script>

<style>
.log {
  background-color: #eee;
  max-height: 250px;
  overflow: scroll;
}
</style>
