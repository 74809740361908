import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('datatable',{attrs:{"loading":_vm.loading,"datos":_vm.recursosList,"pagination":_vm.pagination,"headers":_vm.headers,"page":_vm.pagination.page,"totalItems":_vm.pagination.itemsLength,"itemsPerPage":_vm.pagination.itemsPerPage,"hideSearch":""},on:{"update-page":_vm.updatePageTable,"pagina-change":_vm.paginaCambio},scopedSlots:_vm._u([{key:"item-recurso",fn:function(ref){
var value = ref.value;
return [_c(VListItem,[_c(VListItemAvatar,[(value.tipoRecurso == 'archivo')?_c(VIcon,{attrs:{"color":"primary","small":""}},[_vm._v(_vm._s(value.icono))]):_c(VIcon,{attrs:{"color":"primary","small":""}},[_vm._v("mdi-link-variant")])],1),_c(VListItemContent,[(value.tipoRecurso == 'archivo')?_c('a',{staticClass:"link",on:{"click":function($event){return _vm.viewerItem(value)}}},[_vm._v(_vm._s(value.nombre))]):_c('a',{staticClass:"link",attrs:{"href":value.url,"target":"_blank"}},[_vm._v(_vm._s(value.nombre)+" ")]),_c('ver-mas',{attrs:{"texto":value.descripcion,"longitud":80}})],1)],1)]}},{key:"item-tamanio",fn:function(ref){
var value = ref.value;
return [(value)?_c(VListItem,[_c(VListItemSubtitle,{staticStyle:{"text-align":"center"}},[_c('i',[_vm._v(_vm._s(value))])])],1):_vm._e()]}},{key:"item-usuario",fn:function(ref){
var value = ref.value;
return [_c('h5',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(value))])]}},{key:"item-edicion",fn:function(ref){
var value = ref.value;
return [_c(VMenu,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"margin-left":"10px"},attrs:{"small":"","icon":"","color":"primary","disabled":!_vm.esAdmin || _vm.loading}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"grey"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){return _vm.modificarRecurso(value)}}},[_c(VListItemTitle,[_vm._v("Modificar recurso")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1),_c(VDivider,{staticClass:"mx-3"}),_c(VListItem,{on:{"click":function($event){return _vm.eliminarRecurso(value)}}},[_c(VListItemTitle,[_c('font',{attrs:{"color":"#D32F2F"}},[_vm._v(" Eliminar recurso ")])],1),_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"red darken-2","small":""}},[_vm._v("mdi-trash-can-outline")])],1)],1)],1)],1)]}}])}),(_vm.abrirEditorRecurso)?_c('editorRecurso',{attrs:{"mostrar":_vm.abrirEditorRecurso,"idSubarea":_vm.modificarRecursoObject.subarea,"idArea":_vm.modificarRecursoObject.area,"editarRecurso":_vm.modificarRecursoObject,"tipo":_vm.tipo},on:{"cancelar":function($event){(_vm.abrirEditorRecurso = false), (_vm.modificarRecursoObject = null)},"recursoModificado":_vm.recursoModificado}}):_vm._e(),(_vm.eliminarRecursoObject.mostrar)?_c('eliminarRecurso',{attrs:{"mostrar":_vm.eliminarRecursoObject.mostrar,"recurso":_vm.eliminarRecursoObject.recurso},on:{"cancelar":function($event){(_vm.eliminarRecursoObject.mostrar = false),
        (_vm.eliminarRecursoObject.recurso = null)},"recursoEliminado":function($event){return _vm.$emit('getRecursos')}}}):_vm._e(),(_vm.verArchivo.mostrar)?_c('viewer',{attrs:{"mostrar":_vm.verArchivo.mostrar,"idRecurso":_vm.verArchivo.idRecurso,"nombreArchivo":_vm.verArchivo.nombre,"url":_vm.verArchivo.url,"tipoArchivo":_vm.verArchivo.tipo,"permisos":_vm.verArchivo.permisos},on:{"cerrar":function($event){_vm.verArchivo.mostrar = false}}}):_vm._e(),(_vm.sinArchivo.mostrar)?_c('noFile',{attrs:{"mostrar":_vm.sinArchivo.mostrar,"nombreArchivo":_vm.sinArchivo.nombre},on:{"cerrar":function($event){_vm.sinArchivo.mostrar = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }