<template>
  <v-card outlined>
    <slot name="table-title"></slot>
    <v-container>
      <v-row>
        <v-col cols="6">
          <div class="d-flex" v-if="!hidePagination">
            <v-select
              :items="paginas"
              v-model="paginaSel"
              no-data-text="No hay páginas disponibles"
              dense
              class="pagina"
              @change="paginaChange()"
            />
            <v-data-footer
              :items-per-page-options="[10]"
              :pagination="pagination"
              :options="pagination"
              :loading="loading"
              page-text="{0}-{1} de {2}"
              @update:options="(e) => $emit('update-page', e)"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-if="!hideSearch"
            v-model="search"
            :loading="loading"
            append-icon="mdi-magnify"
            label="Búsqueda"
            hint="Presione 'Enter' para buscar"
            outlined
            dense
            @keyup.enter="$emit('update-search', search)"
            class="search"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="datos"
      :items-per-page="pagination.itemsPerPage || datos.length"
      :loading="loading"
      class="elevation-1"
      :hide-default-footer="true"
      :no-data-text="noDatosText"
    >
      <template #body="{ items, headers }">
        <tbody>
          <tr v-for="(item, row) in items" :key="item._id">
            <td
              v-for="(header, index) in headers"
              :key="`item-${row}-${index}`"
            >
              <slot
                :name="`item-${header.value}`"
                :value="item[header.value]"
                :item="item"
                :row="row"
              >
                {{ item[header.value] }}
              </slot>
            </td>
          </tr>
          <tr v-if="items.length <= 0">
            <td width="100%">
              {{ noDatosText }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: { type: Boolean, default: false },
    datos: { type: Array, required: true },
    pagination: { type: Object, required: true },
    page: { type: Number, default: 0 },
    itemsPerPage: { type: Number },
    totalItems: { type: Number, required: true },
    headers: { type: Array, default: () => [] },
    noDatosText: { type: String, default: "No hay datos disponibles" },
    busquedaLabel: { type: String, default: "Busqueda" },
    hideSearch: { type: Boolean, default: false },
    hidePagination: { type: Boolean, default: false },
  },
  watch: {
    totalItems() {
      this.llenarSelectorPagina();
    },
    page(){
      this.llenarSelectorPagina();
    },
  },
  data: () => ({
    search: "",
    paginas: [],
    paginaSel: 0,
  }),
  mounted() {
    this.llenarSelectorPagina();
  },
  methods: {
    paginate(value) {
      this.$emit("update-page", value);
    },
    searchChange() {
      this.$emit("update-search", this.search);
    },
    paginaChange() {
      this.$emit("pagina-change", this.paginaSel);
    },
    llenarSelectorPagina() {
      this.paginas = [];
      const totalPagina = Math.ceil(
        this.totalItems / this.pagination.itemsPerPage
      );
      for (let index = 0; index < totalPagina; index++) {
        this.paginas.push(index + 1);
      }
      this.paginaSel = this.page != 0 ? this.page : 1;
    },
  },
};
</script>

<style>
.pagina {
  max-width: 60px;
  padding-top: 10px !important;
}
.v-select__selection {
  justify-content: center;
}
</style>