import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSelect,{attrs:{"items":_vm.areasLocales,"item-text":"nombre","item-value":"_id","label":_vm.etiqueta,"no-data-text":_vm.noDataText(),"loading":_vm.loading,"outlined":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.cambiarArea()}},model:{value:(_vm.areaSeleccionadaLocal),callback:function ($$v) {_vm.areaSeleccionadaLocal=$$v},expression:"areaSeleccionadaLocal"}},[(_vm.menu)?_c('template',{slot:"prepend-inner"},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"fab":"","icon":"","x-small":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical ")])],1)]}}],null,false,2987142011)},[_c(VList,[_c(VDivider,{staticClass:"mx-3"}),_vm._l((_vm.menuArea),function(menu,index){return [_c(VListItem,{key:menu.text,attrs:{"dense":"","disabled":menu.disabled || false},on:{"click":function($event){return _vm.$emit(menu.action)}}},[_c(VListItemTitle,[_c('font',{attrs:{"color":menu.color || 'black'}},[_vm._v(_vm._s(menu.text))])],1),(menu.icon)?_c(VListItemIcon,[_c(VIcon,{attrs:{"small":"","color":menu.color || 'grey darken-1'}},[_vm._v(_vm._s(menu.icon))])],1):_vm._e()],1),_c(VDivider,{key:index,staticClass:"mx-3"})]})],2)],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }